.qr-cont {
  min-height: 85vh;
}

.qrcode-logo {
  object-fit: cover;
  max-width: 40%;
}

@media screen and (max-width: 350px) {
  .qrcode-logo {
    width: 40%;
  }
}
