.support_bg {
  background-image: url("../Images/Support_Bg.svg");
  background-size: cover;
  background-position: center center;
  object-fit: cover;
  object-position: 100% 100%;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 22px;
}
.loc{
  width: 35rem;
}
.contact-sec {
  color: var(--close-black);
  font-size: 1.5rem;
  font-weight: 500;
}
.get-in {
  color: var(--close-black);
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
}

.support-text {
  color: #87da9d;
}
a {
  text-decoration: none !important;
}

@media screen and (max-width: 460px) {
  .support-cont span {
    font-size: 18px;
  }
}
