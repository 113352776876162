.writeup {
  max-width: 32.25rem;
  width: 30rem;
}
.writeup:last-of-type {
  max-width: 26.688rem;
  /* width: 26rem; */
}
.topic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1rem;
}
.writeup p {
  font-size: 1.5rem;
  font-weight: 500;
}
.writeup h2 {
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
}
.headingBody {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.topic_logo {
  width: 1.883rem;
  height: 1.393rem;
}
.imageSect {
  max-width: 60%;
  min-width: 30vw;
}
.imageSect img {
  width: 100%;
  display: block;
}
.connect,
.business,
.secureEnvironment,
.multipleValue {
  font-weight: 700;
}
.connect,
.secureEnvironment {
  color: #000533;
}
.multipleValue {
  background: linear-gradient(92deg, #000533 0.11%, #23a5ad 99.82%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.multipleValueApp {
  text-transform: uppercase;
}
.business {
  color: #23a5ad;
}
.link{
  width: 10.25rem;
}
.link img{
  width: 100%;
}
@media all and (max-width: 62.5rem) {
  .writeup {
    max-width: 15.25rem;
    width: 25rem;
  }
  .writeup h2 {
    font-size: 2.7rem;
    font-weight: 500;
    text-transform: capitalize;
  }
}
@media all and (max-width: 48rem) {
  .imageSect {
    min-width: 30vw;
    max-width: 100%;
  }
  /* .writeup {
    max-width: 15.25rem;
    width: 25rem;
  } */
}

@media all and (max-width: 26.563rem) {
  .writeup h2 {
    font-size: 1.5rem;
  }
  .writeup {
    max-width: 100%;
    width: 100%;
  }
  .topic {
    font-size: 0.75rem;
  }
  .topic_logo {
    width: 0.941rem;
    height: 0.697rem;
  }
  .writeup p {
    font-size: 1rem;
  }
  .imageSect {
    width: 100%;
  }
}
