.numerical {
  border-radius: 5px;
  background: linear-gradient(
    92deg,
    rgba(0, 5, 51, 0.2) 0.11%,
    rgba(35, 165, 173, 0.2) 99.82%
  );
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-weight: 500;
  color: #060606;
}
.getStarted h2 {
  font-size: 3rem;
  font-weight: 700;
}
.step-banner {
  width: 80%;
  max-width: 23.125rem;
}
.step-banner p {
  font-size: 2rem;
}
.step,
.step-mobile {
  width: 80%;
  max-width: 22.5rem;
}
.step {
  display: block;
}
.step-mobile{
  display: none;
}
.step h4 {
  font-weight: 600;
  font-size: 1.25rem;
}

@media all and (max-width: 48rem) {
  .step-banner p {
    font-size: 1rem;
  }
  .step {
    display: none;
  }
  .step-mobile {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .step-banner {
    width: 90%;
    max-width: 100%;
  }
  .getStarted h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .step-mobile h4 {
    font-size: 1.125rem;
  }
  .numerical {
    padding: .5rem;
  }
}
