.down-write{
  width: 30rem;
}
.down-write h2{
  color: var(--WHITE, #FFF);
font-size: 4rem;
font-weight: 600;
}
.down-write p{
  color: var(--WHITE, #FFF);
font-size: 2.25rem;
font-weight: 500;
}
.Download-container {
  background-image: url("../Images/DownloadBackground.svg");
  background-size: cover;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.download-frame {
  /* max-width:70%; */
}

@media screen and (max-width: 62.5rem) {
  .download-frame {
    display: block !important;
    margin: auto;
    min-width: 30vw;
    max-width: 70%;
  }
}


@media screen and (max-width: 48rem) {
  .download-heading {
    font-size: 2.4rem;
    max-width: 70%;
  }
  .download-frame {
    max-width: 70%;
  }
  .down-write{
    width: 100%;
  }
  .down-write h2{
  font-size: 2rem;
  }
  .down-write p{
  font-size: 1.125rem;
  }

  .downloading-text {
    text-align: center;
    font-size: 24px;
  }
  
}
@media screen and (max-width: 500px) {
  .download-heading {
    font-size: 2.3rem;
    max-width: 70%;
  }
}

@media screen and (max-width: 360px) {
  .download-heading {
    max-width: 100%;
    font-size: 30px;
  }
}
