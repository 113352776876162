* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

body {
  color: #060606;
  font-size: 1.2rem;
  padding-top: 56px;
}

:root {
  --btn-color: #060606;
  --primary-color: #fff;
  --softpurse-blend: linear-gradient(0deg, #060606, #060606),
    linear-gradient(92.35deg, #000533 0.11%, #23a5ad 99.82%);
  --Green: #12b368;
  --close-black: #060606;
  --Grey: #858597;
}
.bg-color{
  background-color: #f9f9f9;
}

.nav-link {
  color: var(--btn-color) !important;
}

.btn {
  background: var(--btn-color) !important;
}
.Max-width-cont {
  max-width: 80rem;
  width: 90%;
}

.Max-cont-margin-top {
  margin-top: 56px;
}
.fix-down {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.loader {
  padding: 10rem;
  min-height: 70vh;
}
.form-delete {
  flex-grow: 1;
  display: flex;
}

.fix-down-footer {
  position: fixed;
  bottom: 0;
}
