.navbar {
  backdrop-filter: blur(20px);
}
.navbar-nav .nav-item {
  padding-left: 19px;
}

.navbar-toggler {
  border: none !important;
}
.navbar-toggler:focus {
  --bs-navbar-toggler-focus-width: 0 !important;
}



@media screen and (max-width:786px) {
  .navbar-nav .nav-item {
    padding-left: 14px;
  }
}


@media screen and (max-width:360px) {
  .logo-container .logo-image {
    width: 150px;
  }
}
