.hero-heading {
  font-size: 4rem;
  font-weight: 700;
  line-height: 70px;
}
#header{
  background: url('./../Images/bg-hero.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
span {
  color: #23a5ad;
}
.hero {
  padding: 48px;
}

.innercontain {
  max-width: 80rem;
  width: 100%;
}
.hero-image {
  max-width: 100%;
}
.hero_image_div {
  /* width: 50%; */
  width: 39.375rem;
}
.writeup_hero {
  /* width: 50%;
   */
   width: 35rem;
}
.writeup_hero p {
  font-size: 30px;
}
@media screen and (max-width: 880px) {
  .hero-heading {
    font-size: 2.5rem;
    line-height: 50px;
  }
  .hero_image_div {
    width: 100%;
  }
  .writeup_hero p {
    font-size: 25px;
  }
}

@media screen and (max-width: 767px) {
  .hero {
    flex-direction: column !important;
    text-align: center !important;
  }
  .writeup_hero {
    width: 100%;
  }
}

@media screen and (max-width: 485px) {
  .hero-heading {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
  .hero-image {
    width: 314.01px;
  }

  .writeup_hero {
    max-width: 100%;
    width: 100%;
  }

  .hero {
    padding: 0px !important;
  }

  .writeup_hero p {
    font-size: 23px;
  }
}

@media screen and (max-width: 360px) {
  .hero-heading {
    font-size: 30px;
  }
}
