.faq-heading {
  color: var(--Green);
  font-size: 1.25rem;
  font-weight: 600;
}
.question {
  color: var(--close-black);
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  width: 30.625rem;
  margin: auto;
}
.accordion-button {
  color: var(--close-black);
  font-size: 1.5rem;
  font-weight: 500;
}
.accordion-item{
  
}

button.accordion-button {
  color: #060606 !important;
  border: none;
  background-color: white !important;
  box-shadow: none !important;
}

.accordion-body {
  color: var(--Grey);
  font-size: 1.25rem;
}
button:focus:not(:focus-visible) {
  border: none;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 3.125rem !important;
  height: 3.125rem !important;
  border-radius: 50%;
  content: "";
  background-color: #D9D9D9;
  background-image: url("../Images/Arrow.svg") !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.6rem !important;
  transition: transform 0.3s ease-in-out !important;
}
.accordion {
  --bs-accordion-btn-icon-transform: rotate(90deg) !important;
  margin: auto;
  max-width: 900px;
}

@media all and (max-width: 48rem) {
  .question {
    font-size: 1.8rem;
  }
}

@media all and (max-width: 32.75rem) {
  .question {
    width: 100%;
  }
  .faq-heading {
    font-size: 1.125rem;
  }
  .accordion-header {
    font-size: 1.125rem;
  }
  .accordion-body {
    font-size: 1rem;
  }
  .accordion-button::after {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}
