
.level p{
  width: 20rem;
  /* max-width: 22.813rem; */
}
.level div{
  width: 27rem;
  /* max-width: 25rem; */
}

@media all and (max-width: 768px) {
  .level p{
    width: 100%;
    /* max-width: 22.813rem; */
  }
  h3{
    font-size: 1.125rem !important;
  }
  .level div{
    width: 100%;
    /* max-width: 25rem; */
  }
}
