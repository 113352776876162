.footer {
  background-color: #2d2d2d;
  font-size: 1rem;
  /* position: fixed;
  bottom: 0; */
  /* left: 0;
  right: 0; */
}
.inner-container {
  max-width: 80rem;
  width: 90%;
}
hr {
  background-color: white;
}
.footer_logo {
  width: 15rem;
  max-width: 50rem;
}
